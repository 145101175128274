import { getEvents } from '@/api/event'

export default {
  namespaced: true,
  state: {
    events: []
  },
  mutations: {
    setEvents (state, payload) {
      state.events = [...payload]
    }
  },
  actions: {
    fetch ({ commit }, { success, fail } = {}) {
      getEvents(response => {
        commit('setEvents', response.body.data)
        success && success(response)
      }, fail && fail)
    }
  }
}
