<template>
  <div class="flex flex-col text-center">
    <img src="~@/assets/img/offline.jpeg" alt="offline">
    <h2 class="text-blue-600">Koneksi Internetmu Terputus</h2>
    <p class="text-blue-500">Coba check koneksi internetmu,
      lalu refresh halaman ini.</p>
  </div>
</template>

<script>
export default {
  name: 'Offline'
}
</script>

<style scoped>

</style>
