<template>
  <div class="app mx-auto h-screen bg-gray-50">
    <AppBar v-if="isHome"/>
    <AppHeader v-else/>
    <div
      v-if="isOnline"
      class="p-4">
      <router-view/>
    </div>
    <div
      v-show="!isOnline"
      class="p-4"
    >
      <Offline />
    </div>
    <div class="h-16 bg-gray-50"></div>
    <BottomNavigation v-if="isHome"/>
    <Loading v-if="showLoading"/>
  </div>
</template>

<style lang="scss" scoped>
.app {
  max-width: 600px !important;
}
</style>
<script>
import AppBar from '@/components/common/AppBar.vue'
import AppHeader from '@/components/common/AppHeader.vue'
import BottomNavigation from '@/components/common/BottomNavigation.vue'
import Loading from '@/components/common/Loading.vue'
import Offline from '@/components/common/Offline.vue'
import { commonConfig } from '@/config'
import { mapState } from 'vuex'

const modes = ['online', 'offline']

export default {
  name: 'App',
  components: {
    AppBar,
    BottomNavigation,
    AppHeader,
    Loading,
    Offline
  },
  data () {
    return {
      isOnline: true
    }
  },
  created () {
    this.handleCheckOffline()
  },
  computed: {
    ...mapState('common', ['showLoading']),
    currentPage () {
      return this.$route.name
    },
    isHome () {
      return commonConfig.mainMenu.includes(this.currentPage)
    }
  },
  methods: {
    handleCheckOffline () {
      modes.forEach(i => window.addEventListener(i, this.onlineStatusChanged))
    },
    onlineStatusChanged () {
      this.isOnline = navigator.onLine
    }
  }
}
</script>
