import { getPdf, getCategories, getContents } from '@/api/pdf'

export default {
  namespaced: true,
  state: {
    pdfId: '',
    categories: []
  },
  mutations: {
    setPdfId (state, payload) {
      state.pdfId = payload
    },
    setCategories (state, payload) {
      state.categories = [...payload]
    }
  },
  actions: {
    fetch ({ commit }, { success, fail } = {}) {
      getPdf(response => {
        commit('setPdfId', response.body.data)
        success && success(response)
      }, fail)
    },
    fetchCategories ({ commit }, { success, fail } = {}) {
      getCategories(response => {
        commit('setCategories', response.body.data)
        success && success(response)
      }, fail)
    },
    fetchContents ({ commit }, { success, fail, categoryId } = {}) {
      getContents(response => {
        success && success(response.body.data)
      }, fail, categoryId)
    }
  }
}
