import { get } from '@/utils/http-api'
import api from '@/config/api'

const getBanners = (success, fail) => {
  get(api.banner.all, success, fail)
}

export {
  getBanners
}
