
const defaultShareData = {
  title: 'Gunsa App',
  text: 'Hai, ayo semua share applikasi Gunsa ini. Tuhan Yesus memberkati',
  url: 'https://gkigunsa.com/'
}

const share = (data = defaultShareData) => {
  if (navigator.share) {
    navigator.share(data).then(() => {
      console.log('Thanks for sharing!')
    })
      .catch(err => {
        console.log('Couldn\'t share because of', err.message)
      })
  } else {
    console.log('web share not supported')
  }
}

export {
  share
}
