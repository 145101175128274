const applyMeta = ({ title, metaData = [] }) => {
  window.document.title = title || 'GKI Gunsa PWA Apps'
  metaData.forEach(data => {
    const meta = document.createElement('meta')
    meta.setAttribute(data.qualifiedName, data.qualifiedNameValue)
    meta.content = data.value
    document.getElementsByTagName('head')[0].appendChild(meta)
  })
}

export {
  applyMeta
}
