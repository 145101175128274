<template>
  <div class="w-full h-14 bg-blue-300 flex justify-between items-center p-3">
    <img
      class="cursor-pointer w-8 h-8"
      @click="goBack"
      src="~@/assets/icons/arrow-left-white.svg" alt="back">
    <div
      class="cursor-pointer h-8 flex items-center"
      @click="goToHome"
    >
      <img
        class="h-10"
        src="~@/assets/img/logogunsa.png" alt="logo gunsa">
      <img
        class="h-7"
        src="~@/assets/img/logogunsalong.png" alt="logo gunsa long">
    </div>
    <img
      class="cursor-pointer w-8 h-8"
      @click="shareApp" src="~@/assets/icons/share-network-white.svg" alt="share">
  </div>
</template>

<script>
import { share } from '@/utils/share'
import location from '@/mixins/location'
import { mapState } from 'vuex'
export default {
  name: 'AppHeader',
  mixins: [location],
  computed: {
    ...mapState('article', ['detail']),
    ...mapState('fellowship', {
      detailFellowship: 'detail'
    }),
    ...mapState('worship', {
      detailWorship: 'detail'
    }),
    detailArticleShareData () {
      return {
        title: this.detail.title,
        text: this.detail.summary,
        url: `https://gkigunsa.com/detail/${this.detail.id}`,
        image: this.detail.imageUrl
      }
    },
    detailFellowshipShareData () {
      return {
        title: this.detailFellowship.title,
        text: this.detailFellowship.summary,
        url: `https://gkigunsa.com/persekutuan/${this.detailFellowship.id}`,
        image: this.detailFellowship.imageUrl
      }
    },
    detailWorshipShareData () {
      return {
        title: this.detailWorship.title,
        text: this.detailWorship.info,
        url: `https://gkigunsa.com/persekutuan/${this.detailWorship.sequence}`,
        image: this.detailWorship.imageUrl
      }
    }
  },
  methods: {
    goBack () {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push({
          name: 'Home'
        })
      }
    },
    shareApp () {
      if (this.isDetailArticle) share(this.detailArticleShareData)
      else if (this.isDetailFellowship) share(this.detailFellowshipShareData)
      else if (this.isWorship) share(this.detailWorshipShareData)
      else share()
    },
    goToHome () {
      this.$router.push({
        name: 'Home',
        query: this.$route.query
      })
    }
  }
}
</script>

<style scoped>

</style>
