import { get } from '@/utils/http-api'
import api from '@/config/api'

const getFellowship = (success, fail) => {
  get(api.fellowship.all, success, fail)
}

const getDetail = (success, fail, id) => {
  get(api.fellowship.detail(id), success, fail)
}

export {
  getFellowship,
  getDetail
}
