const projectNamespace = '/backend/gki'
const bannerNamespace = `${projectNamespace}/banner`
const articleNamespace = `${projectNamespace}/article`
const fellowshipNamespace = `${projectNamespace}/donation`
const eventNamespace = `${projectNamespace}/events`
const locationNamespace = `${projectNamespace}/files`
const worshipNamespace = `${projectNamespace}/worship`
const pdfNamespace = `${projectNamespace}/pdf`

export default {
  banner: {
    all: `${bannerNamespace}/_all`
  },
  article: {
    all: `${articleNamespace}/_all`,
    detail: id => `${articleNamespace}/${id}`
  },
  fellowship: {
    all: `${fellowshipNamespace}/_all?hide=true`,
    detail: id => `${fellowshipNamespace}/${id}`
  },
  event: {
    all: `${eventNamespace}/_all`
  },
  location: `${locationNamespace}/location`,
  worship: {
    detail: sequence => `${worshipNamespace}/${sequence}`,
    all: `${worshipNamespace}/_all`
  },
  pdf: {
    categories: `${pdfNamespace}/categories`,
    contents: categoryId => `${pdfNamespace}/contents/${categoryId}`
  }
}
