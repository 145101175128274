import { get } from '@/utils/http-api'
import api from '@/config/api'

const getEvents = (success, fail) => {
  get(api.event.all, success, fail)
}

export {
  getEvents
}
