import { getBanners } from '@/api/banner'

export default {
  namespaced: true,
  state: {
    banners: []
  },
  mutations: {
    setBanners (state, payload) {
      state.banners = [...payload]
    }
  },
  actions: {
    fetch ({ commit }, { success, fail } = {}) {
      getBanners(response => {
        commit('setBanners', response.body.data)
        success && success(response)
      }, fail && fail)
    }
  }
}
