import { get, serializeQueryParams } from '@/utils/http-api'
import api from '@/config/api'

const getArticles = (success, fail, queryParams) => {
  const params = serializeQueryParams(queryParams)
  get(api.article.all + params, success, fail)
}

const getDetail = (success, fail, id) => {
  get(api.article.detail(id), success, fail)
}

export {
  getArticles,
  getDetail
}
