const state = {
  showLoading: false
}

const mutations = {
  setLoading (state, payload) {
    state.showLoading = payload
  }
}

const actions = {
  showLoading ({ commit }) {
    commit('setLoading', true)
  },
  hideLoading ({ commit }) {
    commit('setLoading', false)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
