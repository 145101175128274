import { get } from '@/utils/http-api'
import api from '@/config/api'

const getDetailWorship = (success, fail, sequence) => {
  get(api.worship.detail(sequence), success, fail)
}

const getWorships = (success, fail) => {
  get(api.worship.all, success, fail)
}

export {
  getDetailWorship,
  getWorships
}
