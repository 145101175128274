<template>
  <div class="w-full relative z-20 shadow-lg">
    <div class="h-14 bg-blue-300 flex justify-between items-center p-3 space-x-2">
      <img
        class="cursor-pointer w-8 h-8"
        @click="toggleNavDrawer" src="~@/assets/icons/list-white.svg" alt="menu">
      <div
        class="cursor-pointer h-8 flex items-center"
        @click="goToHome"
      >
        <img
          class="h-10"
          src="~@/assets/img/logogunsa.png" alt="logo gunsa">
        <img
          class="h-7"
          src="~@/assets/img/logogunsalong.png" alt="logo gunsa long">
      </div>
      <img
        class="cursor-pointer w-8 h-8"
        @click="shareApp" src="~@/assets/icons/share-network-white.svg" alt="share">
    </div>
    <div v-if="visibleNavDrawer">
      <div class="w-4/6 bg-white h-screen z-20 absolute inset-0 rounded-r-xl p-8">
        <div class="text-xl text-blue-500 font-bold">Menu Lainnya</div>
        <div class="flex-col space-y-4 my-4">
          <div
            @click="redirectTo('Document')"
            :class="['flex items-center space-x-2 cursor-pointer', {'btn': isDocument, 'btn-outline': !isDocument}]">
            <img v-if="isPdf" class="w-8" src="~@/assets/icons/menu/pdf-white.svg" alt="house">
            <img v-else class="w-8" src="~@/assets/icons/menu/pdf-blue.svg" alt="house">
            <div>Media Komunikasi</div>
          </div>
          <div
            @click="redirectTo('Offering')"
            :class="['flex items-center space-x-2 cursor-pointer', {'btn': isOffering, 'btn-outline': !isOffering}]">
            <img v-if="isOffering" class="w-8" src="~@/assets/icons/menu/money-white.svg" alt="house">
            <img v-else class="w-8" src="~@/assets/icons/menu/money-blue.svg" alt="house">
            <div>Persembahan</div>
          </div>
          <div
            @click="redirectTo('About')"
            class="flex items-center space-x-2 cursor-pointer btn-outline">
            <img class="w-8" src="~@/assets/icons/menu/profile-blue.svg" alt="about">
            <div>Tentang GKI Gunsa</div>
          </div>
          <div
            @click="goToRegistration"
            class="flex items-center space-x-2 cursor-pointer btn-outline">
            <img class="w-8" src="~@/assets/icons/menu/calendar-blue.svg" alt="calendar">
            <div>Pendaftaran</div>
          </div>
          <div
            @click="redirectInstallApps"
            class="flex items-center space-x-2 cursor-pointer btn-outline">
            <img class="w-8" src="~@/assets/icons/menu/gear-blue.svg" alt="gear">
            <div>Panduan Install Apps</div>
          </div>
          <div
            class="flex flex-col items-center space-x-2 cursor-pointer">
            <div class="text-blue-500 text-lg">Version: {{ appVersion }} </div>
          </div>
          <div
            class="flex flex-col items-center space-x-2 cursor-pointer">
            <div class="text-blue-500 text-lg">Powered by: </div>
            <img class="h-12" src="~@/assets/img/logotrend.png" alt="logo-trend">
          </div>
        </div>
      </div>
      <div @click="toggleNavDrawer" class="mask w-full h-screen absolute masking Z-20 inset-0"></div>
    </div>
  </div>
</template>

<script>
import { share } from '@/utils/share'
import location from '@/mixins/location'
import { commonConfig } from '@/config'
export default {
  name: 'AppBar',
  mixins: [location],
  data () {
    return {
      visibleNavDrawer: false
    }
  },
  computed: {
    appVersion () {
      return commonConfig.appVersion
    }
  },
  methods: {
    shareApp () {
      if (this.isDetailArticle) share(this.detailArticleShareData)
      else if (this.isDetailFellowship) share(this.detailFellowshipShareData)
      else share()
    },
    toggleNavDrawer () {
      this.visibleNavDrawer = !this.visibleNavDrawer
    },
    redirectTo (name) {
      this.$router.push({
        name
      })
      this.toggleNavDrawer()
    },
    redirectInstallApps () {
      this.$router.push({
        name: 'Player',
        query: {
          v: 'ZeUG5cbcGUQ'
        }
      })
      this.toggleNavDrawer()
    },
    goToHome () {
      this.$router.push({
        name: 'Home',
        query: this.$route.query
      })
    },
    goToRegistration () {
      this.$router.push('/pendaftaran')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
