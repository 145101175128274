import { get } from '@/utils/http-api'
import api from '@/config/api'

const getPdf = (success, fail) => {
  get(api.location, success, fail)
}
const getCategories = (success, fail) => {
  get(api.pdf.categories, success, fail)
}
const getContents = (success, fail, categoryId) => {
  get(api.pdf.contents(categoryId), success, fail)
}

export {
  getPdf,
  getCategories,
  getContents
}
