<template>
  <div class="nav fixed z-10 shadow-lg bottom-0 bg-white h-14 flex shadow-2xl w-full mt-20">
    <div
      @click="redirectTo('Home')"
      :class="['w-1/3 flex flex-col justify-center items-center', {'item': isHome, 'item-outline': !isHome}]">
      <img v-if="isHome" class="w-8" src="~@/assets/icons/menu/house-white.svg" alt="house">
      <img v-else class="w-8" src="~@/assets/icons/menu/house-blue.svg" alt="house">
      <div class="text-sm">Home</div>
    </div>
    <div
      @click="redirectTo('Fellowship')"
      :class="['w-1/3 flex flex-col justify-center items-center', {'item': isFellowShip, 'item-outline': !isFellowShip}]">
      <img v-if="isFellowShip" class="w-8" src="~@/assets/icons/menu/fellowship-white.svg" alt="people">
      <img v-else class="w-8" src="~@/assets/icons/menu/fellowship-blue.svg" alt="people">
      <div class="text-sm">Persekutuan</div>
    </div>
    <div
      @click="redirectTo('Schedule')"
      :class="['w-1/3 flex flex-col justify-center items-center', {'item': isSchedule, 'item-outline': !isSchedule}]">
      <img v-if="isSchedule" class="w-8" src="~@/assets/icons/menu/calendar-white.svg" alt="calendar">
      <img v-else class="w-8" src="~@/assets/icons/menu/calendar-blue.svg" alt="calendar">
      <div class="text-sm">Jadwal</div>
    </div>
  </div>
</template>

<script>
import location from '@/mixins/location'
export default {
  name: 'BottomNavigation',
  mixins: [location],
  methods: {
    redirectTo (name) {
      this.$router.push({
        name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  max-width: 600px;
}
.item {
  @apply p-2 bg-blue-500 text-white text-lg;
}
.item-outline {
  @apply p-2 text-blue-500 text-lg;
}
</style>
