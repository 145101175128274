import { getFellowship, getDetail } from '@/api/fellowship'
export default {
  namespaced: true,
  state: {
    fellowships: [],
    detail: {}
  },
  mutations: {
    setFellowships (state, payload) {
      state.fellowships = [...payload]
    },
    setDetail (state, payload) {
      state.detail = { ...payload }
    }
  },
  actions: {
    fetch ({ commit }, { success, fail, queryParams } = {}) {
      getFellowship(response => {
        commit('setFellowships', response.body.data)
        success && success(response)
      }, fail, queryParams)
    },
    fetchDetail ({ commit }, { success, fail, id }) {
      getDetail(response => {
        commit('setDetail', response.body.data)
        success && success(response)
      }, fail, id)
    },
    resetDetail ({ commit }) {
      commit('setDetail', {})
    }
  }
}
