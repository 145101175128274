import { getArticles, getDetail } from '@/api/article'
export default {
  namespaced: true,
  state: {
    articles: [],
    detail: {},
    pageInfo: {}
  },
  mutations: {
    setArticles (state, payload) {
      state.articles = [...payload]
    },
    setDetail (state, payload) {
      state.detail = { ...payload }
    },
    setPageInfo (state, payload) {
      state.pageInfo = { ...payload }
    }
  },
  actions: {
    fetch ({ commit }, { success, fail, queryParams } = {}) {
      getArticles(response => {
        commit('setArticles', response.body.data)
        commit('setPageInfo', response.body.pageMetaData)
        success && success(response)
      }, fail, queryParams)
    },
    fetchDetail ({ commit }, { success, fail, id }) {
      getDetail(response => {
        commit('setDetail', response.body.data)
        success && success(response)
      }, fail, id)
    },
    resetDetail ({ commit }) {
      commit('setDetail', {})
    }
  }
}
