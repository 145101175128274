import { getDetailWorship, getWorships } from '@/api/worship'

export default {
  namespaced: true,
  state: {
    detail: {},
    worships: [],
    serverTime: 0
  },
  mutations: {
    setDetail (state, payload) {
      state.detail = { ...payload }
    },
    setWorships (state, payload) {
      state.worships = [...payload]
    },
    setServerTime (state, payload) {
      state.serverTime = payload
    }
  },
  actions: {
    fetch ({ commit }, { success, fail, sequence } = {}) {
      getDetailWorship(response => {
        commit('setDetail', response.body.data)
        success && success(response)
      }, fail, sequence)
    },
    fetchWorships ({ commit }, { success, fail } = {}) {
      getWorships(response => {
        commit('setWorships', response.body.data)
        commit('setServerTime', response.body.serverTime)
        success && success(response)
      }, fail)
    }
  }
}
