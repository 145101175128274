const gunsaImpression = ({ page = '', title = '', value = '' }) => {
  // eslint-disable-next-line no-undef
  gtag('event', `${page}-Impression`, {
    event_category: 'Gunsa Impressions',
    event_label: `${title}|${value}`,
    non_interaction: true
  })
}

export {
  gunsaImpression
}
